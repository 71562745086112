document.addEventListener('DOMContentLoaded', () => {
  // do your setup here


  document.getElementById("icon-iphone").addEventListener('mouseover', _ => {
    showImage("iphone");
  });

  document.getElementById("icon-mac").addEventListener('mouseover', _ => {
    showImage("mac");
  });


  const screenshots = document.getElementById("screenshots");

  const showImage = (image) => {
    screenshots.classList.remove("mac");
    screenshots.classList.remove("android");
    screenshots.classList.remove("iphone");
    screenshots.classList.add(image);
  };
  
  
});
